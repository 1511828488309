.main {
  display: flex;
  flex: 2;
  flex-direction: column;
  width: 368px;
}

.main .tabs {
  padding: 0 2px;
  margin: 0 -2px;
}

.main .additional {
  text-align: left;
}

.main .additional .forgot {
  float: right;
}

.main .additional .submit {
  width: 100%;
}

.main .other {
  text-align: left;
  margin-top: 24px;
  line-height: 22px;
}

.main .other .register {
  float: right;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
  width: 100%;
  min-height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: relative;
}

.top {
  display: flex;
  flex: 1;
  qtext-align: center;
}

.header {
  display: flex;
  justify-content: center;
  line-height: 44px;
}

.header a {
  text-decoration: none;
}

.logo {
  vertical-align: top;
  margin-right: 16px;
}

.title {
  font-size: 33px;
  font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
  position: relative;
  top: 2px;
}

.desc {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 40px;
  font-size: 18px;
}

.footer {
  display: flex;
  justify-content: center;
  width: 100%;
  line-height: 14px;
  font-size: 14px;
  padding: 15px;
}

.footer i {
  padding-left: 5px;
  padding-right: 5px;
}
