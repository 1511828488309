body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

/* @media (min-width: 1500px) {
  .container {
    width: 1350px;
  }
} */

@media (min-width: 2000px) {
  .container {
    width: 1800px;
  }
}

@media (min-width: 2500px) {
  .container {
    width: 2200px;
  }
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.containerliquid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 90%;
}

input:focus {
  outline-style: none;
}

.ReactVirtualized__List {
  outline-style: none;
}
